import Header from "../components/header/header";
import HeaderImage from "../images/headers/phonebox.png";
import ContactForm from "../components/extras/contact-us-email";
import Map from "../components/extras/Map"
import React from "react";
import '../App.css';
import '../style/contact.css';

const Contact = () => {
    return (
        <>
            <header>
                <Header text=" " tag="contact" backgroundImage={HeaderImage}/>
            </header>
            <main>
                <div className="contain-contacts">
                    <div className="contact-method">
                        <h2>Direct Message</h2>
                        <ContactForm/>
                    </div>
                    <div className="contact-method">
                        {/* GroupMe QR Codes */}
                        <h3>MDM Announcements</h3>
                        <img src={require("../images/QR-Announcements.png")} alt={"GroupMe Announcements"} loading="lazy"/>
                        <h3>MDM Student Chat</h3>
                        <img src={require("../images/QR-Students.png")} alt={"GroupMe Student Chat"} loading="lazy"/>
                    </div>
                </div>
                {/*<Map/>*/}
            </main>
        </>
    );
}
export default Contact;