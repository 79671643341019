// import React, {useState, useEffect} from 'react';
import React from 'react';
import "./faq.css";

const FAQ = () => {

    // Q&A stored as an array of objects
    const mdmQA = [
        {
            question: "How does registration work?",
            answer: "The registration form link can be found at the top of the MDM website. " +
                "Registration will require you to upload several forms. " +
                "You can register as a team of 4 or 5, or as a partial team or solo. " +
                "If you do not register as a full team, we will do our best to find you a full team but it is not guaranteed.\n"
        },
        // {
        //     q: "What are the restrictions for registration?",
        //     a: "All universities are limited to 1 team except for previous hosts (i.e. UTSA and Texas\n + A&M) " +
        //         "which can register 2 teams. Starting November 11th, all remaining spots will\n" +
        //         "open and there will be no more restrictions on registration. "
        // },
        {
            question: "Who can participate?",
            answer: "This competition is for undergraduate students only and each team must consist of at least 2 underclassmen. " +
                "The MDM is open to all majors."
        },
        {
            question: "Is there a waitlist?",
            answer: "If the competition reaches team capacity, we will open a waitlist. " +
                "There will be a solo waitlist and a team waitlist. " +
                "If there are partial or team dropouts, we will contact those at the top of the waitlist. " +
                "If you are on the waitlist and a spot does not open, you will be refunded your registration fee."
        },
        {
            question: "What do the registration fees cover?",
            answer: "Registration fees cover all meals for the first two days, access to all workshops, mentoring sessions, " +
                "and the networking dinner and career fair. Meals will also be provided on the third day for the top 15 teams. " +
                "Your team will also receive a welcome bag with nametags and the 2025 MDM T-shirt."
        },
        {
            question: "How does judging work?",
            answer: "Each presentation room will have between 6-10 judges who are in the healthcare industry, faculty, or physicians. " +
                "Round 1 and Round 2 will have slightly different rubrics that will be sent to competitors ahead of time. " +
                "The 5 highest scoring teams from each of the 3 rooms will advance to Round 2 where all teams will present " +
                "to the same panel."
        },
        {
            question: "Where do we work on our design?",
            answer: "Each team will be assigned a designated work space before the competition begins." +
                "These will be conference rooms and classrooms most of which have a TV, whiteboard/chalkboard, and a door."
        },
        {
            question: "Are we required to cover our own housing accommodations?",
            answer: "If you wish to stay in a hotel or AirBnB, you will have to pay for it. " +
                "We do have a deal with two local hotels for reduced rates which is detailed in the information packet we sent out. " +
                "If you did not receive this, please email oumdm2025@ou.edu to request it. " +
                "You are also free to sleep in your assigned work space which many teams have done in the past."
        },
        {
            question: "What are prizes?",
            answer: "The top 5 teams are awarded a cash prize ($1,000, $800, $600, $400, $200). " +
                "This prize pool is subject to increase if the budget permits. " +
                "The top 5 teams will also receive custom 3D printed awards and the top team will receive the coveted " +
                "MDM trophy which is passed from school to school each year."
        },
        {
            question: "Is there support for travel?",
            answer: "Currently we are not providing travel support for teams. " +
                "However, if we have the funding to do so, we will reimburse as many of the travel costs as we can. " +
                "We recommend applying for departmental funding support to alleviate travel costs."
        },
        {
            question: "What happens if you are eliminated in Round 1?",
            answer: "If you are eliminated in the first round, you are free to return home or stay in your designated work space for the night. " +
                "We will not be providing food on Sunday, March 2nd for teams eliminated in Round 1."
        },
        {
            question: "Is this competition run by an organization?",
            answer: "No, the MDM is not an official organization. " +
                "This competition is entirely student run and hosting rights are passed from university to university each year."
        },
        {
            question: "How is the host university decided?",
            answer: "After each Make-A-Thon, an informational session will be set up with all universities" +
                "interested in hosting where further details will be provided. " +
                "We will speak more on this at the opening presentation."
        },
        {
            question: "Will we have access to engineering software (i.e. SolidWorks, Fusion 360, etc.)?",
            answer: "Yes, we are working with our engineering department to get temporary logins for each team " +
                "to our computer lab desktops which have all the engineering software you may need to design your device."
        },
        {
            question: "Will we be allowed to leave campus?",
            answer: "Yes, you will be allowed to leave campus whenever you please. " +
                "However, we do ask that teams work on their projects in their designated work spaces."
        },
        {
            question: "Will we have access to showers?",
            answer: "Yes, there will be a 5-6 hour period on Saturday where all participants will be able to shower before Round 1 presentations."
        },
        {
            question: " What is and isn’t allowed?",
            answer: "You are allowed to use the internet, engineering software, AI, textbooks, research papers, etc. " +
                "However, you are not allowed to work with anyone outside of your team including but not limited to " +
                "faculty, physicians, engineers, and family and friends."
        }
    ];

    // useEffect(() => {
    //
    //         const toggleButtons = document.querySelectorAll('.arrows-container');
    //         toggleButtons.forEach((button) => {
    //             button.addEventListener('click', function (e) {
    //                 const faqItem = e.currentTarget.parentElement.parentElement;
    //                 faqItem.classList.toggle('show-answer');
    //             });
    //         });
    //
    // }, []);

    // const [visibleAnswers, setVisibleAnswers] = useState({});
    //
    // const toggleAnswer = (index) => {
    //     setVisibleAnswers((prev) => ({
    //         ...prev,
    //         [index]: !prev[index],
    //     }));
    // };


    return (
        <>
            <div className='faq'>
                <h1>Frequently Asked Questions</h1>
                <div className='FAQs'>
                    {mdmQA.map((qa, index) => (
                        <article className="faq-set" key={index}>
                            <div className="question">
                                <span>{qa.question}</span>
                                <span className="arrows-container">
                                <span className="expand">▼</span>
                                <span className="close">▲</span>
                            </span>
                            </div>
                            <div className="answer">
                                <span>{qa.answer}</span>
                            </div>
                        </article>
                    ))}
                </div>
            </div>
        </>
    );
};

export default FAQ;