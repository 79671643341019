import React from "react";
import {Route, Routes} from "react-router-dom"
import Navbar from "./components/navigation/navbar";
import Home from "./pages/Home";
import Sponsor from "./pages/Sponsor";
import History from "./pages/History";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Info from "./pages/Info";
import Logo25 from "./images/MDM-Logo.png";
import './App.css';
import Logo from "./images/mdm2025.png";
import Explore from "./pages/Explore";
import 'normalize.css';
// import Gallery from "./pages/Gallery";


const App = () => {

    return (
        <>
            <head>
                <meta charSet="UTF-8" />
                <link rel="icon" href="/mdmicon.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta property="og:title" content="MDM" />\
                <meta name="description" content="Medical Device Make-A-Thon is a student lead competition" />
                <meta name="author" content="Haley Perez - University of Oklahoma"/>
            </head>
            <Navbar />
            <div className="container">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/sponsor" element={<Sponsor />} />
                    {/*<Route path="/explore" element={<Explore />} />*/}
                    <Route path="/team" element={<Team />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/previous-competitions" element={<Gallery />} />
                    <Route path="/info" element={<Info />} />
                </Routes>
            </div>
            <div className="footer">
                <div className="foot-section">
                    <p>Medical Device Make-A-Thon is a student led competition</p>
                    <p>February 28th through March 2nd, 2025</p>
                    {/*https://www.instagram.com/medical_device_makeathon/*/}
                </div>
                {/*<img src={Logo25} alt={"MDM 2025 Logo"} loading="lazy"/>*/}
                <img src={Logo} alt={"MDM 2025 Logo"} loading="lazy"/>

                <div className="foot-section">
                    <p>Hosted by the University of Oklahoma</p>
                    <p>Co-Hosted by the University of Illinois Urbana-Champaign</p>
                </div>
            </div>
        </>
    )
}

export default App;
