import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import "./email.css"

const ContactForm = () => {
    // Form Reference
    const form = useRef();
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm();
    const [disabled, setDisabled] = useState(false);
    const [alertInfo, setAlertInfo] = useState({display: false, message: '', type: '',});

    // Shows alert message for form submission feedback
    const toggleAlert = (message, type) => {
        setAlertInfo({ display: true, message, type });

        // Hide alert after 5 seconds
        setTimeout(() => {
            setAlertInfo({ display: false, message: '', type: '' });
        }, 5000);
    };

    // Function uses email.js to send email
    const onSubmit = async (data) => {

        const {name, affiliate, email, subject, message} = data;

        try {
            // Disable form while processing submission
            setDisabled(true);

            // Must match in email.js template
            const templateParams = {name, affiliate, email, subject, message};

            // Log into Email.js website to change where template sends the message
            await emailjs.send(
                'service_hbnwkec',        // Service ID   (Email Services)
                'template_contact_mdm',  // Template ID  (Email Templates)
                templateParams,
                'JdaZbxt2zK9LWgAfB'         // Public Key   (Account)
            );

            // Submission Feedback
            toggleAlert('Form submission was successful!', 'success');
        } catch (e) {
            console.error(e);
            toggleAlert('Uh oh. Something went wrong.', 'danger');
        } finally {
            // Re-enable form & reset
            setDisabled(false);
            reset();
        }
    };

    return (
        <div className='ContactForm'>
            <form ref={form} onSubmit={handleSubmit(onSubmit)} noValidate>
                {/* 1st Row */}
                <div className='formRow'>
                    <div className='form-entry'>
                        <input type='text' name='name' className='formInput' placeholder='Name'
                               {...register('name', {
                                   required: 'Please enter your name',
                                   maxLength: {
                                       value: 30,
                                       message: 'Please use 30 characters or less',
                                   },
                               })}
                        />
                        {errors.name && (<span className='errorMessage'>{errors.name.message}</span>)}
                    </div>
                    <div className="form-entry">
                        <input type='email' name='email' className='formInput' placeholder='Email'
                               {...register('email', {
                                   required: 'Please enter your email',
                                   pattern: {
                                       value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                       message: 'Please enter a valid email address',
                                   },
                               })}
                        />
                        {errors.email && (<span className='errorMessage'>{errors.email.message}</span>)}
                    </div>
                </div>

                {/* 2nd Row */}
                <div className='formRow'>
                    <div className="form-entry">
                        <input type='text' name='affiliate' className='formInput' placeholder='University or Company'
                               {...register('affiliate', {
                                   required: 'Please enter your affiliation',
                               })}
                        />
                        {errors.affiliate && (<span className='errorMessage'>{errors.affiliate.message}</span>)}
                    </div>
                    <div className='form-entry'>
                        <input type='text' name='subject' className='formInput' placeholder='Subject'
                               {...register('subject', {
                                   required: 'Please enter a subject',
                                   maxLength: {
                                       value: 75,
                                       message: 'Subject cannot exceed 75 characters',
                                   },
                               })}
                        />
                        {errors.subject && (<span className='errorMessage'>{errors.subject.message}</span>)}
                    </div>
                </div>

                {/* 3rd Row */}
                <div className='formRow'>
                    {/*<div className="message">*/}
                    <textarea
                        rows={8}
                        name='message'
                        {...register('message', {
                            required: 'Please enter a message',
                        })}
                        className='form-control formInput'
                        placeholder='Message'
                    ></textarea>
                    {errors.message && (
                        <span className='errorMessage'>{errors.message.message}</span>
                    )}
                    {/*</div>*/}
                </div>

                <button
                    className='submit-btn btn btn-primary'
                    disabled={disabled}
                    type='submit'
                >
                    Submit
                </button>
            </form>
            {alertInfo.display && (
                <div
                    className={`alert alert-${alertInfo.type} alert-dismissible mt-5`}
                    role='alert'
                >
                    {alertInfo.message}
                    <button
                        type='button'
                        className='btn-close'
                        data-bs-dismiss='alert'
                        aria-label='Close'
                        onClick={() =>
                            setAlertInfo({ display: false, message: '', type: '' })
                        }
                    ></button>
                </div>
            )}
        </div>
    );
};

export default ContactForm;
